import Head from "next/head"
import Image from "next/image"
import { useRouter } from "next/router"
import { Col, Container, Row } from "react-bootstrap"
import { StyledButton } from "@modules/Common/styled"
import Page from "@modules/Common/components/ClientPage"
import Header from "@modules/Common/components/Header"

export default function NotFound() {
  const router = useRouter()
  return (<Page>
    <Head>
      <title>Page Not Found | My Skill Shaala</title>
    </Head>
    <Header />
    <Container className="text-center">
      <Row>
        <Col lg={{ span: 8, offset: 2 }}>
          <Image src="/assets/img/not-found-placeholder.jpeg" height={400} width={400} alt="Activity Not Found" />
          <div style={{ fontSize: 20, fontWeight: 700 }}>The page you&apos;re trying to access is not exists. Please Contact us if you think you&apos;re looking on the right page.
          </div>
          <StyledButton onClick={() => router.push("/activities")}>
            Check Other Activities
          </StyledButton>
        </Col>
      </Row>
    </Container>
  </Page>
  )
}
