import { useContext, useEffect, useState } from "react"
import { Button, Col } from "react-bootstrap"
import { StyledContainer, StyledHeader } from "@styled/commonHeader"
import { AppContext } from "AppContext"
import { HeaderRow, UserBlock } from "@styled/dashboard"
import Link from "next/link"
import { useRouter } from "next/router"
import styled from "styled-components"
import { isEmpty } from "lodash"
import Image from "next/image"

export const BackButton = styled.button`
  background: url(/assets/svg/back-button-new.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  border: none;
  height: 50px;
  width: 50px;
  margin-left: 15%;
`

interface HeaderPops {
  showCoins?: string
}

export default function Header({ showCoins = "false" }: HeaderPops) {
  const router = useRouter()
  const { state } = useContext(AppContext)
  const [isDashboard, setIsDashboard] = useState(false)

  useEffect(() => {
    if (router.pathname == "/dashboard") {
      setIsDashboard(true)
    }
  }, [router])

  return (
    <StyledContainer className="px-0" fluid>
      <StyledHeader className="no-gutters">
        <Col xs={12} sm={12} md={5} lg={4} xl={4} className="header-col">
          {(isDashboard === false) &&
            <div className="back-button">
              <BackButton onClick={() => router.back()} />
            </div>}
          <Link passHref href={state.user ? "/dashboard" : "/login"} legacyBehavior>
            <img src="/assets/img/logo.png" alt="Logo" className="logo-image" />
          </Link>
        </Col>
        <Col>
          <HeaderRow>
            <Col md={10}>
              <UserBlock coins={showCoins}>
                {isDashboard ? <h2 className="user-name">
                  Hello <span style={{ color: "#FECC06" }}>{state.user?.displayName || ""}</span>,
                  <br />
                  Let&apos;s Learn Together
                </h2> : <div className="back-button-lg">
                  <BackButton onClick={() => router.back()} />
                </div>}
                <div className="balance-block tour-step-coins">
                  <div>
                    <img src="/assets/img/coin.png" alt="coin" className="coin" />
                    <span className="current-balance">{`${state.user?.coins} ${(state.user?.coins === 0 || state.user?.coins === 1) ? "COIN" : "COINS"}`}</span>
                    <Link href="/purchase/coins" passHref legacyBehavior><Button variant="secondary" style={{ color: "var(--bs-primary)", fontWeight: "bold", borderRadius: "40px", marginLeft: "8px" }}>ADD</Button></Link>
                  </div>
                </div>
              </UserBlock>
            </Col>
          </HeaderRow>
        </Col>
      </StyledHeader>
    </StyledContainer>
  )
}
