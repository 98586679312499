import { useEffect, useState, ReactNode } from "react"
import styled from "styled-components"

type Props = {
  children?: ReactNode
}

const StyledDiv = styled.div`
`

export default function Page({ children }: Props) {
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])

  return mounted ? <StyledDiv>{children}</StyledDiv> : null
}
