import styled from "styled-components"
import { Row } from "react-bootstrap"
import { XCircle } from "react-bootstrap-icons"

export const Page = styled.div`
  text-align: center;
  min-height: 80vh;
`

export const FeaturedActivity = styled(Row)`
  white-space: nowrap;
  justify-content: center;
  margin: 2em;

  & span {
    background: url(/assets/img/featured-activity.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    color: white;
    font-weight: bold;
    font-size: 2em;
    padding: 1.25em 2em;
    width: auto;
  }
  @media (max-width: 576px) {
    & span {
      font-size: 1.65em;
    }
  }
  @media (max-width: 767px) {
    margin: 2em 0;
  }
`

export const ActionRow = styled(Row)`
  cursor: pointer;
  & .activities {
    background: url(/assets/img/activities.png);
  }
  & .workshops {
    background: url(/assets/img/workshop.png);
  }
  & .explorations {
    background: url(/assets/img/explorations.png);
    padding-left: 2em !important;
    padding-right: 1em !important;
  }
  & .products {
    background: url(/assets/img/products.png);
  }
  & > div.action {
    font-weight: bold;
    font-size: 1.5em;
    background-repeat: no-repeat;
    padding: 5.5em 2em 1.5em 1em;
    margin-top: 2em;
    background-position: center;
    background-size: contain;
  }
  @media (max-width: 1199px) {
  }
  @media (max-width: 991px) {
    padding: 15px;
    & > div.action {
      padding-top: 8em;
    }
    .activities {
      order: 1;
    }
    .explorations {
      order: 3;
    }
    .workshops {
      order: 2;
    }
    .products {
      order: 4;
    }
  }
  @media (max-width: 576px) {
    & > div.action {
      margin-top: 0;
    }
     & .activities {
      font-size: 1.2em !important;
      padding-top: 6em !important;
     }
     & .workshops {
      font-size: 1.2em !important;
      padding-top: 6em !important;
     }
     & .explorations {
      font-size: 1.2em !important;
      padding-top: 5.3em !important;
     }
     & .products {
      font-size: 1em !important;
      padding-top: em !important;
     }
  }
  @media (max-width: 410px) {
  }
`

export const SliderWrapper = styled.div`
  .row > * {
    width: auto;
  }
  padding: 1em;
  & .slick-arrow::before {
    content: none;
  }
  & .slick-arrow {
    content: none;
    height: 40px;
    width: 40px;
  }
  & .slick-prev {
    left: -45px;
  }
  & .slick-next {
    right: -45px;
  }
  @media (max-width: 1199px) {
    padding: 0 1em;
  }
  @media (max-width: 991px) {
  }
  @media (max-width: 576px) {
    padding: 0 3em;
  }
`

export const HeaderRow = styled(Row)`
  height: 100%;
`

interface UserBlockProps {
  coins: string
}

export const UserBlock = styled.div<UserBlockProps>`
  color: white;
  font-weight: bold;
  height: 100%;
  display: flex;
  flex-direction: column;
  & .balance-block {
    font-size: 1.3em;
    margin-left: 2em;
    display: ${props => props.coins === "true" ? "flex" : "none"};
    white-space: nowrap;
    margin-top: 100px;
    background: url(/assets/svg/coin-balance-background.svg);
    background-repeat: no-repeat;
    background-size: contain;
    padding: 0.4em 0.6em;
    align-items: center;
    & > div > * {
    }
    & img.coin {
      height: 48px;
      @media (max-width: 576px) {
        height: 32px;
      }
    }
    & .current-balance {
      margin-left: 8px;
      align-items: center;
      color: #ffd632;
    }
  }

  .user-name {
    font-weight: 600;
  }

  @media (min-width: 1024px) {
    margin-top: 1.5em;
    margin-left: 18%;
  }

  @media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (-webkit-min-device-pixel-ratio: 1) {
    & .balance-block {
      margin-left: 8px;
    }
    .user-name {
      color: white;
    }
  }

  @media only screen
  and (max-device-width: 768px) {
    color: #6530d9;
    font-size: 1.25em;
    margin-bottom: 8px;
    & .balance-block {
      margin-left: 8px;
      margin-top: 12px;
      & > div {
        padding: 0 0.5em;
        position: relative;
        right: 16px;
      }
    }
  }
`

export const ModelCloseButton = styled(XCircle)`
  cursor: pointer;
  font-size: 1.5em;
`
