import styled from "styled-components"
import { Row, Container } from "react-bootstrap"

export const StyledContainer = styled(Container)`
  padding: 0px;
  overflow: hidden;
`

export const StyledHeader = styled(Row)`
  background: url(/assets/img/header.png);
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: row-reverse;
  & .logo-image {
    height: 60%;
    margin-top: 2em;
  }
  & .back-button{
    display: none;
  }
  & .back-button-lg{
    text-align: left;
    height: 80px;
  }
  @media (min-width: 1200px) {
    background-size: 100% 100%;
  }
  @media (min-width: 992px) {
    background-size: 100% 85%;
    .logo-image {
      padding-left: 1em;
    }
    & .header-col{
      text-align: center;
      height: 300px;
    }
  }
  @media (max-width: 992px) {
    background-size: 130% 85%;
    background-position-x: right;
  }
  @media (max-width: 767px) {
    background: url(/assets/svg/mobile-header.svg);
    background-size: contain;
    background-repeat: no-repeat;
    .logo-image {
      position: relative;
      margin-bottom: 30px;
      margin-top: 7%;
      float: right;
      right: 12%;
    }
    & .back-button{
      position: relative;
      float: left;
      left: 5%;
      top: 20%;
    }
    & .back-button {
      display: block;
  }
    & .header-col{
      height: 260px;
    }
    & .back-button-lg{
      display: none;
    }
  }
  @media (max-width: 576px) {
    .logo-image {
      height: auto;
      width: 120px;
      padding-left: 0.5em;
    }
    & .header-col {
      height: 140px;
    }
  }
  @media (max-width: 415px) {
    .logo-image {
      height: auto;
      width: 100px;
      padding-left: 0;
      margin-top: 5%;
    }
  }
`
